import { DateTimePicker } from '@mui/x-date-pickers'
import withMui from 'hoc/withMuiTheme'
import moment from 'moment-timezone'
import 'moment/locale/sv'
import { themeColor } from 'utils/theme'
import './DatePicker.scss'

interface PropsTypes {
  handleChange: any
  label: string
  value: Date | moment.Moment
  defaultValue?: Date | moment.Moment
  disabled?: boolean
}

function DateFieldValue({
  label,
  handleChange,
  defaultValue,
  disabled,
  value,
}: PropsTypes) {
  return (
    <div className="date-picker-container">
      <DateTimePicker
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: themeColor,
            },
            '&:hover fieldset': {
              borderColor: '#35787b',
            },
            '&.Mui-focused fieldset': {
              borderColor: '##35787b',
            },
          },
        }}
        ampm={false}
        disabled={disabled ?? false}
        onChange={handleChange}
        label={label}
        value={moment(value as moment.Moment).locale('sv')}
        defaultValue={moment(defaultValue as moment.Moment).locale('sv')}
      />
    </div>
  )
}

export default withMui(DateFieldValue)
