import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc)
dayjs.extend(timezone)
export function convertToLocalDate(dateString: Date) {
    const returnDate = dayjs(dateString).toDate().toLocaleString('sv-SE', {
        timeZone: 'Europe/Stockholm'
    })

    return dayjs(returnDate).toDate()
}
export default convertToLocalDate