import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import React from 'react'
import { themeColor } from 'utils/theme'
import styles from './Select.module.scss'

interface BasicSelectProps {
  label: string
  defaultInput: string
  values: Array<{ name: string; _id: number | string }>
  handleChange: (e: SelectChangeEvent) => void
  value: string | number
}

const BasicSelect: React.FC<BasicSelectProps> = ({
  label,
  defaultInput,
  values,
  handleChange,
  value,
}) => {
  const [focused, setFocused] = React.useState(false)

  return (
    <Box sx={{ minWidth: 120, margin: '0.5rem' }}>
      <FormControl
        fullWidth
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: themeColor,
            },
            '&:hover fieldset': {
              borderColor: '#35787b',
            },
            '&.Mui-focused fieldset': {
              borderColor: '##35787b',
            },
          },
        }}
      >
        <InputLabel
          id="demo-simple-select-label"
          shrink={focused || Boolean(value)}
        >
          {defaultInput}
        </InputLabel>
        <Select
          className={styles.select_container}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value as any}
          label={label}
          onChange={handleChange}
        >
          {values?.length > 0 &&
            values.map(
              (item: { name: string; _id: number | string }, i: number) => (
                <MenuItem value={item.name} key={i}>
                  {item.name.slice(0, 50)}
                </MenuItem>
              )
            )}
        </Select>
      </FormControl>
    </Box>
  )
}

export default BasicSelect
