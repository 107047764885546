import CalendarNavigation from 'components/CalendarNavigation'
import Info from 'components/Info'
import Label from 'components/Label'
import LeaveCard from 'components/LeaveCard'
import LeaveStatusInfo from 'components/LeaveStatusInfo'
import Loading from 'components/Loading'
import Modal from 'components/Modal'
import Select from 'components/Select'
import getColorsByType from 'functions/getColorsByType'
import getLeaveStatusInfo from 'functions/getStatusInfoByType'
import { Leave, TimesVariationsEnum } from 'generated/graphql'
import withMuiTheme from 'hoc/withMuiTheme'
import useAuth from 'hooks/Auth.hook'
import { useTheme } from 'hooks/theme.hook'
import useTime from 'hooks/Time.hook'
import moment, { Moment } from 'moment'
import { useEffect, useState } from 'react'
import { FaUserCircle } from 'react-icons/fa'
import { MdClose, MdDone, MdFilterAltOff, MdTask } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import { LeaveStatusEnum, LeavesVariationsEnum } from 'types/sharedTypes'
import routes from 'utils/routes.json'
import { themeColor } from 'utils/theme'
import './LeaveRapport.scss'

interface LeavesByUser {
  [userEmail: string]: Leave[]
}

function LeavesStatus() {
  const [success, setSuccess] = useState(false)
  const [currentLeave, setCurrentLeave] = useState<Leave>()
  const [statusQuery, setStatusQuery] = useState('')
  const [typeQuery, setTypeQuery] = useState('')
  const [formErrors, setFormErrors] = useState<any>({})
  const [filteredLeaves, setFilteredLeaves] = useState<LeavesByUser[]>([])
  const navigate = useNavigate()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const themeObj = useTheme()
  const location = useLocation()
  const { user, userLoading } = useAuth()
  const [currentMonth, setCurrentMonth] = useState<Moment>(moment())
  const goToPreviousWeek = () => {
    setCurrentMonth((prev) => prev.clone().subtract(1, 'month'))
  }

  const goToNextWeek = () => {
    setCurrentMonth((prev) => prev.clone().add(1, 'month'))
  }

  const {
    setLeaveStatusMutation: [
      setLeave,
      { loading, data, error: setLeaveError, reset },
    ],
    userLeavesByAdmin: [
      getUserLeaves,
      { data: userLeaves, loading: getUserLevesLoading },
    ],
    setLeaveStatus: [
      setNewLeaveStatus,
      {
        error: leaveStatusError,
        loading: leaveStatusLoading,
        data: leaveStatusData,
      },
    ],
  } = useTime()
  function groupLeavesByUser(leaves: Leave[]): LeavesByUser {
    return leaves.reduce((acc: LeavesByUser, leave) => {
      const userEmail = leave.user?.username
        ? leave.user?.username
        : leave.user?.email
      if (userEmail) {
        if (!acc[userEmail]) {
          acc[userEmail] = []
        }
        acc[userEmail].push(leave)
      }
      return acc
    }, {} as LeavesByUser)
  }
  const groupedLeaves = userLeaves?.getUserLeavesByAdmin
    ? statusQuery && typeQuery
      ? groupLeavesByUser(
          (userLeaves.getUserLeavesByAdmin as Leave[]).filter(
            (r) =>
              r.status?.toString() === statusQuery &&
              r.leaveType?.toString() === typeQuery
          )
        )
      : statusQuery
      ? groupLeavesByUser(
          (userLeaves.getUserLeavesByAdmin as Leave[]).filter(
            (r) => r.status?.toString() === statusQuery
          )
        )
      : typeQuery
      ? groupLeavesByUser(
          (userLeaves.getUserLeavesByAdmin as Leave[]).filter(
            (r) => r.leaveType?.toString() === typeQuery
          )
        )
      : groupLeavesByUser(userLeaves.getUserLeavesByAdmin as Leave[])
    : {}
  const validateForm = () => {
    let errors: any = {}

    return Object.keys(errors).length === 0
  }
  async function _fetchLeaves() {
    await getUserLeaves({
      variables: {
        beginDateQuery: moment(currentMonth).startOf('month'),
        finishDateQuery: moment(currentMonth).endOf('month'),
      },
    })
  }
  useEffect(() => {
    if (!user?.currentUser && !userLoading) {
      navigate('/')
    }
    _fetchLeaves()
  }, [location, currentMonth, user])

  function onMoreInfo(leave: Leave) {
    setCurrentLeave({ ...leave })
    setModalIsOpen(true)
  }

  async function submitNewStatus(
    leaveId: string,
    userId: string,
    status: string
  ) {
    try {
      setSuccess(false)
      await setNewLeaveStatus({
        variables: {
          userId: userId,
          leaveId: leaveId,
          status:
            status === 'Accept'
              ? 'Accepted'
              : status === 'Reject'
              ? 'Rejected'
              : 'Pending',
        },
        onCompleted: () => {
          setTimeout(() => {
            setModalIsOpen(false)
            reset()
            navigate(0)
            setSuccess(true)
          }, 1000)
        },
        onError: () => {
          setTimeout(() => {
            setSuccess(true)
          }, 1500)
        },
      })
    } catch (error) {
      setSuccess(true)
      return
    }
  }

  return (
    <div
      className={`leave-status ${
        themeObj?.theme === 'dark'
          ? 'leave-status--dark-theme'
          : 'leave-status--light-theme'
      }`}
    >
      <Label label={routes.check_status.component} />
      <div>
        {' '}
        <CalendarNavigation
          goToNextWeek={goToNextWeek}
          goToPreviousWeek={goToPreviousWeek}
          currentDate={currentMonth}
          type="month"
        />
      </div>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
        {' '}
        <div className="select-wrapper" style={{ width: '100%' }}>
          {userLeaves?.getUserLeavesByAdmin && (
            <Select
              value={statusQuery}
              label="Status"
              defaultInput="Status"
              values={[...Object.values(LeaveStatusEnum)]
                .filter((r) => typeof r == 'string')
                .map((key, i) => ({
                  name: key as string,
                  _id: i,
                }))}
              handleChange={(e) => setStatusQuery(e.target.value)}
            />
          )}
          {userLeaves?.getUserLeavesByAdmin && (
            <Select
              value={typeQuery}
              label="Fronvaråtyp"
              defaultInput="Frånvarotyp"
              values={[...Object.values(LeavesVariationsEnum)]
                .filter((r) => typeof r == 'string')
                .map((key, i) => ({
                  name: key as string,
                  _id: i,
                }))}
              handleChange={(e) => setTypeQuery(e.target.value)}
            />
          )}
        </div>{' '}
        <MdFilterAltOff
          className="icon"
          size={25}
          onClick={() => {
            setStatusQuery('')
            setTypeQuery('')
          }}
        />
      </div>

      <Modal
        isModalOpen={modalIsOpen}
        title="Frånvaro"
        setIsModalOpen={() => !modalIsOpen}
      >
        <div className="modal-con">
          <LeaveStatusInfo leave={currentLeave as Leave} />
          <div className="icon-cont">
            <button
              style={{ border: '1px solid green' }}
              className="icon"
              onClick={async () =>
                await submitNewStatus(
                  currentLeave?._id as string,
                  currentLeave?.user?.id as string,
                  'Accept'
                )
              }
            >
              <MdDone
                color="green"
                style={{ color: 'green !important' }}
                size={33}
              />
            </button>
            <button
              style={{ border: '1px solid red' }}
              className="icon"
              onClick={async () =>
                await submitNewStatus(
                  currentLeave?._id as string,
                  currentLeave?.user?.id as string,
                  'Reject'
                )
              }
            >
              <MdClose
                color="red"
                size={33}
                style={{ color: 'red !important' }}
              />
            </button>
          </div>
          {leaveStatusError && !leaveStatusLoading ? (
            <Info type="error">{leaveStatusError.message}</Info>
          ) : null}

          {leaveStatusData?.setLeaveStatus && !leaveStatusLoading ? (
            <Info type="success">Status changed</Info>
          ) : null}

          {leaveStatusLoading ? <Loading /> : ''}
          <div>
            <MdClose
              color="white"
              size={30}
              onClick={() => setModalIsOpen(false)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </div>
      </Modal>

      {userLeaves?.getUserLeavesByAdmin?.length === 0 &&
      !getUserLevesLoading ? (
        <Info type="warning">{`OBS! Hittades ingen frånvaro för ${currentMonth.format(
          'MMMM'
        )}`}</Info>
      ) : null}
      {getUserLevesLoading ? <Loading /> : null}
      <div
        className="leave-status__items-wrapper"
        style={{
          boxShadow:
            Object.entries(groupedLeaves)?.length === 0 ? 'initial' : undefined,
        }}
      >
        <div>
          {Object.entries(groupedLeaves).length
            ? Object.entries(groupedLeaves).map(
                ([userEmail, leaves], index) => (
                  <div key={index} className="user-leave-card">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: '10',
                      }}
                    >
                      <h3
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          width: 'fit-content',
                          boxShadow: '#00f7ff37 0px 2px 4px 3px 3px',
                          borderBottomWidth: 0,
                          padding: '1em',
                          margin: 0,
                          borderTopLeftRadius: '7px',
                          borderTopRightRadius: '7px',
                          position: 'relative',
                          zIndex: 3,
                          bottom: '0px',

                          backgroundColor: '#00f7ff37',
                          borderBottom: 'none',
                        }}
                      >
                        <span>
                          <FaUserCircle
                            style={{ marginRight: '5px' }}
                            size={30}
                            color={'#49b4b8'}
                          />
                        </span>
                        <span
                          style={{
                            color:
                              themeObj?.theme === 'dark'
                                ? 'whiteSmoke'
                                : 'black',
                          }}
                        >
                          {' '}
                          {userEmail}
                        </span>
                      </h3>

                      {statusQuery ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <MdTask
                              style={{ marginRight: '5px' }}
                              size={20}
                              color={'#49b4b8'}
                            />
                          </span>
                          <span> {statusQuery}</span>
                        </div>
                      ) : null}
                    </div>
                    <div
                      style={{
                        borderRadius: '7px',
                        boxShadow: 'gray 0px 2px 4px 0px',
                        borderBottomWidth: 0,
                        border: '1px solid #gray',
                        padding: '2em 0',
                        marginBottom: '1rem',
                        zIndex: 1,
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '7px',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <div
                        className="leave-item-header"
                        style={{
                          color: themeColor,
                        }}
                      >
                        <span>Frånvaro</span>
                        <span>Status</span>
                        <span>Starttid</span>
                        <span>Sluttid</span>
                        <span>Info</span>
                      </div>
                      <div className="info-card">
                        {' '}
                        {leaves.map((leave, i) => {
                          const { statusColor, ItemsIcon } = getLeaveStatusInfo(
                            leave.status as string,
                            leave.leaveType as TimesVariationsEnum
                          )
                          let leaveColor = getColorsByType(
                            leave.leaveType as string
                          )

                          return (
                            <LeaveCard
                              key={leave._id}
                              id={leave?._id as string}
                              statusColor={statusColor}
                              type={leave?.leaveType as TimesVariationsEnum}
                              icon={ItemsIcon}
                              beginDate={leave.beginDate}
                              finishDate={leave.finishDate}
                              onClick={onMoreInfo}
                              leave={leave}
                              leaveColor={leaveColor}
                            />
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              )
            : null}
        </div>
      </div>
    </div>
  )
}

export default withMuiTheme(LeavesStatus)
